define("discourse/plugins/discourse-ai/initializers/ai-sentiment-admin-nav", ["exports", "discourse/lib/api"], function (_exports, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _api.apiInitializer)("1.15.0", api => {
    const settings = api.container.lookup("service:site-settings");
    if (settings.ai_sentiment_enabled) {
      api.addAdminSidebarSectionLink("reports", {
        name: "sentiment_overview",
        route: "admin.dashboardSentiment",
        label: "discourse_ai.sentiments.sidebar.overview",
        icon: "chart-column"
      });
      api.addAdminSidebarSectionLink("reports", {
        name: "sentiment_analysis",
        route: "adminReports.show",
        routeModels: ["sentiment_analysis"],
        label: "discourse_ai.sentiments.sidebar.analysis",
        icon: "chart-pie"
      });
    }
  });
});